







import Vue from "vue";
import { ServedCode } from "../../model/entity/orderSetting";

export default Vue.component("OrderListServing", {
  components: {
    HOOrderListTab: () =>
      import("../../components/compositions/HOOrderListTab.vue"),
    HOOrderList: () =>
      import("../../components/components/HOOrderList.vue"),
  },
  data() {
    return {
      servedReady: ServedCode.SERVED_READY,
    };
  }
});
